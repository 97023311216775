import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faTwitter,
    faFacebook,
    faInstagram,
    faYoutube,
    faReact,
    faJsSquare,
    faNode,
    faAws,
    faDocker,
    faHtml5,
    faCss3Alt,
    faSass,
    faSwift,
    faLinkedinIn,
    faGithub,
} from '@fortawesome/free-brands-svg-icons'
import { faLightbulb as farLightbulb } from '@fortawesome/free-regular-svg-icons'
import {
    faMoon,
    faSun,
    faLightbulb,
    faVideo,
    faVideoSlash,
    faToggleOff,
    faToggleOn,
} from '@fortawesome/free-solid-svg-icons'
library.add(
    faTwitter,
    faFacebook,
    faInstagram,
    faYoutube,
    faMoon,
    faSun,
    faReact,
    faJsSquare,
    faNode,
    faAws,
    faDocker,
    faHtml5,
    faCss3Alt,
    faSass,
    faSwift,
    faLightbulb,
    farLightbulb,
    faVideo,
    faVideoSlash,
    faToggleOff,
    faToggleOn,
    faLinkedinIn,
    faGithub
)
